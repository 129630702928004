<template>



    <div class="container">



        <div class="row">
            <div class="col-md-12 col-xs-12">

            <div class="row">
                    <div class="col-md-6 col-xs6">
                   
                </div>

 <div class="col-md-6 col-xs6">
                <div class="invoice-title">
                    <div style="width:85px;height:90px;">
                        <img src="http://localhost:8080/assets/logo2.png" style="with:100%;height:100%">
                    </div>
                </div>
 </div>

            </div>

                <hr>

                <div class="row">


                    <div class="col-md-4 col-xs-6">

                    </div>


                    <div class="col-md-4 col-xs-6 ">

                    </div>


                    <div class="col-md-2 col-xs-6 text-left">
                        <address>
                            <strong>{{bills[0].user.full_name}} </strong><br>
                            {{BillsSum(bills)}}<br>
                            {{bills[0].created_at}}

                        </address>
                    </div>
                    <div class="col-md-2 col-xs-6">
                        <address>
                            <strong>: اسم التاجر </strong><br>
                            <strong>: مجموع الفاتوره </strong><br>
                            <strong>: تاريخ الفاتوره </strong><br>
                        </address>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <table class="table">
                <thead>
                    <tr>

                        <th scope="col">
                            تاريخ الفاتوره
                        </th>

                        <!-- <th scope="col">
                        استقطاع المنصه
                    </th> -->



                        <th scope="col">
                            قيمه الفاتوره
                        </th>

                        <th scope="col">
                            اسم الخدمه
                        </th>

                        <th scope="col">
                            اسم الزبون
                        </th>
                        <th scope="col">
                            رقم الفاتوره
                        </th>




                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in bills" :key="item.id">

                        <td>{{item.created_at}}</td>
                        <td>د.ع {{item.amount}} </td>
                        <td>{{item.item.item_name}}</td>
                        <td>{{item.user.full_name}}</td>
                        <td>{{ item.id }}</td>






                    </tr>

                    <tr>

                        <td></td>
                        <!-- <td>د.ع {{counts.podium_Deduction}}   </td> -->
                        <td> {{BillsSum(bills)}} </td>

                        <td></td>
                        <td></td>
                        <td></td>



                    </tr>

                </tbody>
            </table>
        </div>

    </div>
</template>


<script>
    export default {
        inheritAttrs: false,

        props: {
            bills: Array,
            counts: Array
        },

        methods: {

            BillsSum(bills_amount) {
                var totle_coast = 0;

                for (var i = 0; i < bills_amount.length; i++) {
                    totle_coast += bills_amount[i].amount;

                }
                return totle_coast;

            },
            count_amount(amount) {
                amount
                //   for()

                //   var abount_all='';
                //   return abount_all+=amount;
            }
        }
    }
</script>