<template>
    <v-layout column="" row wrap pr-3 mr-2 mt-1>
        <!-- SOURCE -->
        <div id="printMe" style="display:none">
            <billsReport :bills="selectedBills" :counts="counts"></billsReport>
        </div>
        <!-- OUTPUT -->


        <v-dialog v-model="BillsDialog" width="600px" dense>

            <v-card style="padding:20px">
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-right">
                                    اسم الخدمه
                                </th>
                                <th class="text-right">
                                    قيمه الفاتور
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in selectedBills" :key="item.id">
                                <td>{{ item.item.item_name}}</td>
                                <td>{{ item.amount }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <br>
                <br>
                <!-- <v-divider></v-divider> -->

                <p>مجموع الفاتوره : {{BillsSum(selectedBills)}}</p>

                <v-layout row wrap>
                    <v-flex xs3>
                        <v-btn color="green" style="color:#fff" @click="print()">طباعه الفاتوره</v-btn>
                    </v-flex>

                     <v-flex xs6>
                        <v-btn color="blue" style="color:#fff" >دفع الفاتوره عن طريق زين كاش</v-btn>
                    </v-flex>

                     <v-flex xs3>
                        <v-btn color="red" style="color:#fff"  @click="BillsDialog=false">خروج</v-btn>
                    </v-flex>

                </v-layout>

            </v-card>
        </v-dialog>


        <v-row>

            <v-data-table :headers="headers" :items="bills" show-select v-model="selectedBills" sort-by="calories"
                class="elevation-1 request_table" style="width:96%" items-per-page="15" hide-default-footer=""
                :loading="loading" loading-text="جاري تحميل البيانات">

                <template v-slot:top v-slot:item.amount="{ item }">
                    <v-toolbar flat color="white" pb-5>

                        <v-toolbar-title>فواتير التاجر :
                            <span style="color:blue">
                                {{bills[0].owner.owner_barnd_name}}
                            </span>


                        </v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>



                        <v-row justify="end" style="margin-top:20px;" v-if="selectedBills.length>0">
                            <div style="width:auto">

                                <v-btn color="success" dark class="mb-2" @click="BillsDialog=true">
                                    <span style="margin-left:5px;font-family:'cairo_Semi'">دفع الفاتوره</span>
                                    <v-icon size="15">fas fa-print</v-icon>

                                </v-btn>
                            </div>



                        </v-row>



                        <!-- <v-row justify="end" style="margin-top:20px;">
                            <div style="width:auto">

                                <v-btn color="success" dark class="mb-2"
                                    @click="print">
                                    <span style="margin-left:5px;font-family:'cairo_Semi'">طباعه تقرير</span>
                                    <v-icon size="15">fas fa-print</v-icon>

                                </v-btn>
                            </div>



                        </v-row> -->



                    </v-toolbar>
                </template>


                <template v-slot:bottom>
                    <v-pagination v-model="page" :length="pageCount"></v-pagination>
                </template>



                <template v-slot:item.amount="{ item }">

                    <span>{{item.amount}} د.ع </span>

                </template>


                <template v-slot:item.podium_Deduction="{ item }">

                    <span>{{item.podium_Deduction}} د.ع </span>

                </template>



                <template v-slot:item.created_at="{ item }">

                    <span>{{getDateOnly(item.created_at)}}</span>

                </template>

            </v-data-table>

        </v-row>
        <v-row>
            <!-- <v-pagination v-model="page"  prev-icon="mdi-menu-right" next-icon="mdi-menu-left"
                circle="" :length="pageCount"></v-pagination> -->
        </v-row>
    </v-layout>


</template>


<script>
    import billsReport from './sub_components/billsReport.vue';
    export default {
        data: () => ({
            bills: [],
            selectedBills: [],
            BillsDialog: false,
            owner_id: '',

            editedItem: {

                output: null,

                user: {
                    created_at: "",
                    full_name: "",
                    id: "",
                    mobile_token: "",
                    remember_token: "",
                    role_id: "",
                    password_confirmation: "",
                    status_id: "",
                    updated_at: "",
                },

                owner_category: [{
                    id: "",
                    category_id: "",
                    owner_id: "",
                    status_id: "",
                    created_at: "",

                }],

                owner_description: "",
                owner_email: "",
                password: "",
                user_phone: "",
                owner_barnd_name: "",
                owner_phone: "",
                status_id: "",
                owner_type_id: "",
                province_id: "",
                category_id: "",
                long: "66",
                lat: "32",

            },

            calander_dialog: false,
            err_show: false,
            error: '',
            search: '',

            item_selected: {},
            loding_accept: false,
            loding_cancel: false,
            headers: [{
                    text: '#',
                    align: 'center',
                    sortable: false,
                    value: 'id',
                },
                {
                    text: 'اسم الزبون',
                    align: 'center',
                    sortable: false,
                    value: 'user.full_name',
                },
                {
                    text: 'اسم الخدمه',
                    align: 'center',
                    sortable: false,
                    value: 'item.item_name',
                },
                {
                    text: 'قيمه الفاتوره',
                    align: 'center',
                    sortable: false,
                    value: 'amount',
                },

                {
                    text: 'طريقه الدفع',
                    align: 'center',
                    sortable: false,
                    value: 'payment_method.name',
                },


                {
                    text: 'تاريخ الفاتوره',
                    value: 'created_at',
                    sortable: false
                },

            ],
            editedIndex: -1,
            valid: false,
            page: 1,
            pageCount: 0,
            current_page: 0,
            last_page: 0,
            loading: false,
            counts: []


        }),

        updated() {},
        methods: {
            BillsSum(bills_amount) {
                var totle_coast = 0;

                for (var i = 0; i < bills_amount.length; i++) {
                    totle_coast +=bills_amount[i].amount;

                }
                return totle_coast;

            },
            print() {
                this.$htmlToPaper('printMe');
            },
            getDateOnly(date) {
                if (date != null) {
                    return date.split("T")[0];
                } else {
                    return "--------"
                }

            },
            editItem(item) {
                this.editedIndex = 1;
                this.editedItem = Object.assign({}, item);
                this.dialog = true;
            },

            getOwnerBills() {

                var url = "/bills/operation/search?filter[item.owner_id]=" + parseInt(this.owner_id) +
                    "&filter[status_id]=31";
                this.loading = true;
                this.$http({
                    method: 'get',
                    url: url,
                    headers: {

                    }

                }).then(response => {
                    this.bills = response.data.data;
                    if (this.bills.length == 0) {
                        this.$swal('خطاء', "لاتوجد فواتير لهاذا التاجر", 'error')
                        this.$router.push({
                            name: "ownerBills"
                        })
                    }
                    this.counts = response.data.meta.counts

                }).catch(error => {
                    error
                    //this.$swal('خطاء', "خطاء بالاتصال", 'error')
                }).finally(s => {
                    s,
                    this.loading = false;
                });
            },

        },

        mounted() {
            this.owner_id = this.$route.params.id;
            this.getOwnerBills();


        },

        components: {


            billsReport

        },


        watch: {
            selected: 'search by sub_cat_id',
        },
    }
</script>